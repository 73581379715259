.modal-section {
  display: flex;
  justify-content: space-between;
}

.input-modal {
  display: flex;
  flex-direction: column;
  width: 33%;
  padding-left: 30px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.checkbox-modal {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;

}

.margin-left-check {
  margin-left: 2rem;
}

.modal-documentacion {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 5rem;
}

.select-documentacion {
  height: 20px
}

.modal-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
}


/* MODAL VALIDATION  */

.modal-validation {
  display: flex;
  justify-content: space-around;
  width: 100%;
  overflow-y: scroll;
  height: 400px
}

.divider-vertical {
  height: 45rem;
  border: 1px solid #707070;
  margin-left: 1rem;
  margin-right: 1rem;
}

.divider-horizontal {
  width: 100%;
}

.left-modal {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.right-modal {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.modal-ref-validation {
  display: flex;
  flex-direction: column;
}


.modal-textarea {
  width: 100%
}

.document-element {
  display: flex;
}