.card-component {
  display: flex;
  justify-content: space-between;
  border: 1px solid #707070;
  border-radius: 10px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 2rem;
  box-sizing: border-box;
  width: 700px;
  color: #718195;
}

.left-section-card {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.btn-fill-gray {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 30px;
  border: none;
  padding: 10px;
  background-color: gray;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.btn-fill-gray:active {
  transform: scale(.9);
}

.margin-element {
  margin-top: 1rem;
}

.width-text {
  width: 400px
}

.info-extend {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.source {
  font-weight: bold;
  margin-top: 0.5rem;
}

.property-title {
  font-weight: bold;
  color: #718195
}

.value-title {
  font-weight: normal;
  color: #718195
}

.right-section-card {
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 200px
}

.right-section-spacebetween {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.btn-fill-orange {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: fit-content;
  padding: 0 10px;
  background-color: #FF861B;
  color: white;
  font-family: 'Raleway', sans-serif;
  border-radius: 20px;
  cursor: pointer;
}

.btn-fill-red {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: fit-content;
  padding: 0 10px;
  background-color: #D85652;
  color: white;
  font-family: 'Raleway', sans-serif;
  border-radius: 20px;
  cursor: pointer;
}

.btn-fill-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: fit-content;
  padding: 0 10px;
  background-color: #5276d8;
  color: white;
  font-family: 'Raleway', sans-serif;
  border-radius: 20px;
  cursor: pointer;
}

.btn-fill-grayblue {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 30px;
  padding: 10px;
  background-color: var(--gray-color);
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.btn-width {
  width: 100px
}

.btn-fill-orange:active {
  transform: scale(.9);
}

.btn-fill-grayblue:active {
  transform: scale(.9);
}

.btn-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: rgb(180, 180, 180);
  color: white;
  border-radius: 5px;
  padding: 10px;
  height: 30px;
  cursor: pointer;
}

.btn-disabled:active {
  transform: scale(.9);
}

.buttons-prospect {
  display: flex;
  flex-direction: column;
}

.buttons-actions-special{
  display: flex;
  justify-content: space-between;
}

.btn-fill-transparet{
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #707070;
}

.btn-fill-transparet:active {
  transform: scale(.9);
}

.edit-element-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 35px;
}

/* CUSTOM SIGNATURE CARD  */
.contain-selects {
  display: flex;
  flex-direction: column;
  height: 25rem;
  justify-content: space-around;
  align-items: center;
}

.select-file-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FF861B;
  border-style: dashed;
  height: 80px;
  width: 80px;
}

.select-icons {
  font-size: 35px;
  margin-bottom: 0.5rem;
}

.contain-btn-signa {
  display: flex; 
  justify-content: space-between;
}

.document-element {
  border: 2px solid #71D300;
  padding: 7px;
  background-color: white;
  color: #718195
}

/* CUSTOM INVENTORY CARD */
.img-card {
  width: 300px;
  object-fit: cover;
}

.content-column-top {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.content-column-bottom {
  display: flex;
  flex-direction: column;
}

.content-row-buttons {
  display: flex;
  justify-content: space-between;
}