.deed-text {
  margin: 0px;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: start;
}


.deed-button {
  width: 100%;
  color: white;
  margin: 0.4rem 0;
  border-radius: 20px;
  border: none;
  padding: 0.3rem;
  background-color: #FF861B;
  transition: all 0.5ms ease;
}

.deeb-secondary {
  background-color: #718195;
}


.deed-button:disabled,
.deed-button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.deed-error {
  background: #dc2727;
  padding: .2rem 3rem;
  color: white;
  border-radius: 20px;
}