.nav button{
  text-decoration: none;
  font-size: 14px;
  background-color: rgb(243, 243, 243);
  width: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgb(112, 112, 112);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-family: 'Raleway', sans-serif;
}
.nav button:not(:last-child){
  margin-right: 5px;
}

.map-container{
  width: 50%;
  padding-left: 50px;
}
.map-container path{
  fill: #718195;
  stroke: white;
  stroke-width: 4px;
  transition: all .3s ease;
}

.map-container path:hover{
  fill: #45c97c;

  stroke-width: 4px;
}