.categories-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width:90%;
}

.category-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(243, 243, 243);
  width: 150px;
  height: 60px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-family: 'Raleway', sans-serif;
}

.text-gray {
  color: #718195;
}

.text-red {
  color: #FF564B;
}

.text-orange {
  color: #FF861B;
}

.text-green {
  color: #71D300;
}

.margin-category {
  margin-left: 0.3rem;
}