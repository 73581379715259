:root {
  --gray-color: #718195;
  --orange-color: #FF861B;
  --red-color: #FF564B;
  --green-color: #71D300;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.blue-text{
  color: #089EDF;
}
.text-orange {
  color: var(--orange-color);
}

.text-red {
  color: var(--red-color)
}

.text-green {
  color: var(--green-color)
}

.text-gray {
  color: var(--gray-color)
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 87vh;
  overflow-y: scroll;
  width: 100%;
  margin-top: 13vh;
  margin-left: 147px;
}

.home-info-container {
  display: grid;
  grid-template-columns: 3fr 4fr;
  column-gap: 40px;
}

.disabled {
  cursor: not-allowed !important;
}
*::-webkit-scrollbar {
  width: 0.8em;
  border-radius: 1em;
  background: rgba(
    255,
    255,
    255,
    0.1
  ); /* Light background for the scrollbar for contrast */
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1em;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(rgba(241, 89, 42, 0.6), rgba(241, 89, 42, 0.9));
  outline: 1px solid #F1592A;
  border-radius: 1em;
  transition:
    background 0.3s,
    transform 0.3s; /* Transition for smooth effects */
}

/* Hover effect */
*::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(rgba(241, 89, 42, 0.8), rgba(241, 89, 42, 1));
  transform: scale(1.05); /* Slightly enlarge the thumb for feedback */
}

.filepond--item {
    width: calc(25% - 0.5em);
    display: inline-block;
}