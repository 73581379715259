.popUp {
  z-index: 3;
  width: 10em;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popUp a {
  text-decoration: underline;
}

.popUp h5 {
  color: #212427;
  font-family: "Trebuchet MS", sans-serif;
  font-size: 1rem;
}

.mapboxgl-popup-close-button {
  position: absolute;
  z-index: 99999;
  font-size: 1.8em;
  width: 24px;
  height: 24px;
  right: 0;
  top: 0;
  border: 0;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
}

.mapboxgl-popup-close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
