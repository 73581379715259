.navbar-section-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13vh;
  width: 100%;
  background-color: white;
  border-bottom: 2px solid #707070;
  position: fixed;
}

.navbar-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  position: relative;
  font-size: 18px;
}

.nav-logo {
  position: absolute;
  left: 0px;
  width: 200px;
  cursor: pointer;
}

.header-title {
  text-transform: capitalize;
  color: #f1592a;
  margin: 0px;
}
