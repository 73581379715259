.sidebar-section-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147px;
  height: 87vh;
  background-color: #718195;
  position: fixed;
  margin-top: 13vh;
}

.navigate-section-container {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.navigate-icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

.nav-icon-size {
  font-size: 30px;
  margin-bottom: 0.4rem;
}

.divider {
  border: 1px solid white;
  width: 80%
}