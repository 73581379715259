/* MAIN STYLES SCREENS */

.frame-layout {
  display: flex;
  flex-direction: column;
}

.frame-layout-container {
  display: flex;
}

.list-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 87vh;
  overflow-y: scroll;
  width: 100%;
  margin-top: 13vh;
  margin-left: 147px;
}

.historial-back {
  display: flex;
  align-items: center;
  font-size: 27px;
  color: #FF861B;
  font-weight: bold;
  cursor: pointer
}

.historial-layout {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  height: 100vh;
  width: 100%;
  margin-top: 13vh;
  margin-left: 147px;
  position: fixed;
}

.left-historial {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100%;
  overflow-y: scroll;
  padding: 2rem;
  width: 60%;
  padding-bottom: 9rem;
}

.right-historial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  width: 30%;
  height: 100%;
  text-align: left;
  box-sizing: border-box
}
